<template>
  <a-tabs v-model:activeKey="activeKey">
    <a-tab-pane key="0" tab="站内信模板">
      <table-page :config="config" />
    </a-tab-pane>
    <a-tab-pane key="1" tab="push模板">
      <table-page :config="configRecommend" />
    </a-tab-pane>
  </a-tabs>
</template>

<script>
import TablePage from "@/components/TablePage";
import { recipient } from '@/utils/public';

export default {
  name: 'strategyInfoDetail',
  components: { TablePage },
  props: ['selectedRows'],
  data() {
    return {
      activeKey: '0',
      config: {
        dataUrl: `/message/template/list?templateType=website`,
        filter: {
          controls: [
            {
              key: 'title',
              label: '消息标题',
              type: 'text'
            },
            {
              key: 'receiveMsgType',
              label: '接收对象',
              type: 'select',
              config: {
                options: recipient
              }
            },
          ]
        },
        columns: [
          {
            key: 'id',
            title: '消息模板ID',
            width: '150px',
          },
          {
            key: 'receiveMsgType',
            title: '接收对象',
            type: 'textCustom',
            width: '100px',
            config: {
              text: (val) => {
                return val == 'userMessage' ? '用户消息' : val == 'orgMessage' ? '机构消息' : ''
              }
            }
          },
          {
            key: 'title',
            title: '消息标题',
            width: '150px',
          },
          {
            key: 'summary',
            title: '消息摘要',
            width: '200px',
            type: 'text',
            ellipsis: true
          },
          {
            key: 'lastModifiedDate',
            title: '编辑时间',
            width: '130px',
            type: 'datetime'
          },
          {
            key: 'name',
            title: '操作',
            type: 'actions',
            fixed: 'right',
            width: document.documentElement.clientWidth <= 768 ? '70px' : '166px'
          }
        ],
        /* 表格操作 */
        actions: [
          {
            key: 'edit',
            text: '新建模板',
            type: 'form',
            permission: '/message/template/add',
            config: {
              color: 'primary',
              title: '新建模板',
              submitUrl: "/message/template/add",
              submitText: '保存',
              controls: [
                {
                  key: 'templateType',
                  label: '模板类型',
                  type: 'select',
                  required: true,
                  disabled: true,
                  defaultValue: 'website',
                  config: {
                    options: [
                      {
                        value: 'website',
                        text: '站内信',
                      }
                    ]
                  }
                },
                {},
                {
                  key: 'receiveMsgType',
                  label: '接收对象',
                  type: 'radio',
                  required: true,
                  config: {
                    initialValue: 'userMessage',
                    options: [
                      {
                        text: '用户消息',
                        value: 'userMessage'
                      },
                      {
                        text: '机构消息',
                        value: 'orgMessage'
                      },
                    ]
                  }
                },
                {},
                {
                  key: 'title',
                  label: '消息标题',
                  type: 'text',
                  required: true,
                  config: {
                    rules: [
                      { min: 1, max: 50, message: '请尽量精简内容，输入50字以内' }
                    ]
                  }
                },
                {
                  key: 'summary',
                  label: '摘要',
                  type: 'textarea',
                  required: true,
                  config: {
                    rules: [
                      { min: 1, max: 500, message: '请尽量精简内容，输入500字以内' }
                    ]
                  }
                },
                {
                  key: 'content',
                  label: '正文',
                  type: 'editor',
                  required: true,
                  config: {
                    uploadUrl: '/api//message/template/addUpload'
                  }
                },
              ]
            }
          },
        ],
        /* 表格排序 */
        sorter: {
          /* 可排序列 */
          sortableColumns: [''],
          /* 默认排序 */
          defaultSort: ''
        },
        rowActions: [
          {
            key: 'edit',
            text: '编辑',
            type: 'form',
            permission: '/message/template/edit',
            config: {
              color: 'primary',
              title: '编辑',
              initUrl: "/message/template/edit/get",
              submitUrl: "/message/template/edit",
              submitText: '保存',
              controls: [
                {
                  key: 'templateType',
                  label: '模板类型',
                  type: 'select',
                  required: true,
                  disabled: true,
                  defaultValue: 'website',
                  config: {
                    options: [
                      {
                        value: 'website',
                        text: '站内信',
                      }
                    ]
                  }
                },
                {},
                {
                  key: 'receiveMsgType',
                  label: '接收对象',
                  type: 'radio',
                  required: true,
                  config: {
                    initialValue: 'userMessage',
                    options: [
                      {
                        text: '用户消息',
                        value: 'userMessage'
                      },
                      {
                        text: '机构消息',
                        value: 'orgMessage'
                      },
                    ]
                  }
                },
                {},
                {
                  key: 'title',
                  label: '消息标题',
                  type: 'text',
                  required: true,
                  config: {
                    rules: [
                      { min: 1, max: 50, message: '请尽量精简内容，输入50字以内' }
                    ]
                  }
                },
                {
                  key: 'summary',
                  label: '摘要',
                  type: 'textarea',
                  required: true,
                  config: {
                    rules: [
                      { min: 1, max: 500, message: '请尽量精简内容，输入500字以内' }
                    ]
                  }
                },
                {
                  key: 'content',
                  label: '正文',
                  type: 'editor',
                  required: true,
                  config: {
                    uploadUrl: '/api//message/template/addUpload'
                  }
                },
              ]
            }
          },
          {
            key: 'lock',
            text: '删除',
            type: 'confirm',
            permission: '/message/template/delete',
            disabled: ({ selectedRows }) => {
              return true;
            },
            config: {
              color: 'primary',
              submitColor: 'primary',
              submitUrl: '/message/template/delete'
            }
          },
        ]
      },
      configRecommend: {
        dataUrl: `/message/template/list?templateType=push`,
        filter: {
          controls: [
            {
              key: 'title',
              label: '消息标题',
              type: 'text'
            },
          ]
        },
        columns: [
          {
            key: 'id',
            title: '消息模板ID',
            width: '150px',
          },
          {
            key: 'title',
            title: '消息标题',
            width: '150px',
          },
          {
            key: 'content',
            title: '消息内容',
            width: '200px',
            ellipsis: true
          },
          {
            key: 'landUrl',
            title: '链接',
            width: '100px',
          },
          {
            key: 'lastModifiedDate',
            title: '编辑时间',
            width: '130px',
            type: 'datetime'
          },
          {
            key: 'name',
            title: '操作',
            type: 'actions',
            fixed: 'right',
            width: document.documentElement.clientWidth <= 768 ? '70px' : '166px'
          }
        ],
        /* 表格操作 */
        actions: [
          {
            key: 'edit',
            text: '新建模板',
            type: 'form',
            permission: '/message/template/add',
            config: {
              color: 'primary',
              title: '新建模板',
              submitUrl: "/message/template/add",
              submitText: '保存',
              controls: [
                {
                  key: 'templateType',
                  label: '模板类型',
                  type: 'select',
                  required: true,
                  disabled: true,
                  defaultValue: 'push',
                  config: {
                    options: [
                      {
                        value: 'push',
                        text: 'push',
                      }
                    ]
                  }
                },
                {
                  key: 'receiveMsgType',
                  label: '接收对象',
                  type: 'select',
                  required: true,
                  disabled: true,
                  defaultValue: 'userMessage',
                  config: {
                    options: [
                    {
                        text: '用户消息',
                        value: 'userMessage'
                      },
                    ]
                  }
                },
                {
                  key: 'title',
                  label: '消息标题',
                  type: 'text',
                  required: true,
                  config: {
                    rules: [
                      { min: 1, max: 50, message: '请尽量精简内容，输入50字以内' }
                    ]
                  }
                },
                {
                  key: 'content',
                  label: '消息内容',
                  type: 'textarea',
                  required: true,
                  config: {
                    rules: [
                      { min: 1, max: 500, message: '请尽量精简内容，输入500字以内' }
                    ]
                  }
                },
                {
                  key: 'landUrl',
                  label: '落地页链接',
                  type: 'text',
                  required: true,
                  config: {
                  }
                },
                {}
              ]
            }
          },
        ],
        /* 表格排序 */
        sorter: {
          /* 可排序列 */
          sortableColumns: [''],
          /* 默认排序 */
          defaultSort: ''
        },
        rowActions: [
          {
            key: 'edit',
            text: '编辑',
            type: 'form',
            permission: '/message/template/edit',
            config: {
              color: 'primary',
              title: '编辑',
              initUrl: "/message/template/edit/get",
              submitUrl: "/message/template/edit",
              submitText: '保存',
              controls: [
                {
                  key: 'templateType',
                  label: '模板类型',
                  type: 'select',
                  required: true,
                  disabled: true,
                  defaultValue: 'push',
                  config: {
                    options: [
                      {
                        value: 'push',
                        text: 'push',
                      }
                    ]
                  }
                },
                {
                  key: 'receiveMsgType',
                  label: '接收对象',
                  type: 'select',
                  required: true,
                  disabled: true,
                  defaultValue: 'userMessage',
                  config: {
                    options: [
                    {
                        text: '用户消息',
                        value: 'userMessage'
                      },
                    ]
                  }
                },
                {
                  key: 'title',
                  label: '消息标题',
                  type: 'text',
                  required: true,
                  config: {
                    rules: [
                      { min: 1, max: 50, message: '请尽量精简内容，输入50字以内' }
                    ]
                  }
                },
                {
                  key: 'content',
                  label: '消息内容',
                  type: 'textarea',
                  required: true,
                  config: {
                    rules: [
                      { min: 1, max: 500, message: '请尽量精简内容，输入500字以内' }
                    ]
                  }
                },
                {
                  key: 'landUrl',
                  label: '落地页链接',
                  type: 'text',
                  required: true,
                  config: {
                  }
                },
                {}
              ]
            }
          },
          {
            key: 'lock',
            text: '删除',
            type: 'confirm',
            permission: '/message/template/delete',
            disabled: ({ selectedRows }) => {
              return true;
            },
            config: {
              color: 'primary',
              submitColor: 'primary',
              submitUrl: '/message/template/delete'
            }
          },
        ]
      }
    };
  }

};
</script>

<style lang="less" scoped></style>